<template>
  <div class="user-rules">
    <div v-if="nextProgram && prevProgram" class="nav">
      <button type="button" class="btn btn_header ripple" @click="getPrev">&lt; Programa Anterior</button>
      <button type="button" class="btn btn_header ripple" @click="getNext">Programa Seguinte &gt;</button>
    </div>
    <div class="middle">
      <transition name="fade">
        <div v-if="isLoading" class="loading">
          <div class="loader">
            <svg
              class="spinner"
              width="65px"
              height="65px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                class="path"
                fill="none"
                stroke-width="6"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              />
            </svg>
          </div>
        </div>
      </transition>
      <div class="input-group name">
        <div class="item full">
          <input v-model="name" type="text" required />
          <span class="highlight" />
          <span class="bar" />
          <label>Nome do programa</label>
        </div>
      </div>
     <!--
      <div class="input-group name">
        <div class="item full">
          <input v-model="imageUrl" type="text" required />
          <span class="highlight" />
          <span class="bar" />
          <label>Imagem do Programa</label>
        </div>
      </div>
    -->
      <div class="option-group">
        <div class="head">
          <span>Horários</span>
        </div>
        <div class="input-group hours">
          <div class="item half">
            <input v-model="startTimeConvet" type="time" />
            <span class="highlight" />
            <span class="bar" />
            <label />
          </div>
          <div class="item half">
            <input v-model="endTimeConvert" type="time" />
            <span class="highlight" />
            <span class="bar" />
            <label />
          </div>
        </div>
      </div>
      <div class="option-group">
        <div class="input-group hours">
          <div class="body">
            <label class="option" for="isTomorrow">
              <span>Depois da meia-noite</span>
              <div class="switch">
                <input id="isTomorrow" v-model="isTomorrow" type="checkbox" />
                <span class="slider ripple" />
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <footer v-if="message || messageError" class="done" :class="{ error: messageError }">
      <div class="input-group">
        <div class="item full">
          <i class="icone material-icons">{{ messageError ? "mood_bad" : "mood" }}</i>
          <input v-model="searchInput" type="text" required />
          <span class="highlight" />
          <span class="bar" />
          <label>{{ message || messageError }}</label>
        </div>
      </div>
    </footer>
    <footer v-else>
      <!-- <button type="button"
              class="btn btn_secondary ripple"
              :class="{ disabled : !isValidate }"
              @click="clone"
      >
        Clonar
      </button>-->
      <button
        type="button"
        class="btn btn_primary ripple"
        :class="{ disabled : !isValidate }"
        @click="addProgram"
      >Salvar</button>
    </footer>
  </div>
</template>

<script>
import bffRealtime from "@/gateways/bff-realtime";
import dateUTC from "@/utils/dateUTC";
import moment from "moment";

export default {
  props: {
    program: {
      type: Object,
      default: null
    },
    updateProgram: {
      type: Function,
      default: null
    },
    nextProgram: {
      type: Function,
      default: null
    },
    prevProgram: {
      type: Function,
      default: null
    }
  },
  mixins: [dateUTC],
  data() {
    return {
      interval: { h: 1, m: 1, s: 10 },
      total: 0,
      active: true,
      searchInput: "",
      search: "",
      isLoading: false,
      activeUser: "",
      message: "",
      messageError: "",
      messageTimeout: null,
      id: null,
      name: "",
      imageUrl: "",
      startTime: null,
      endTime: null,
      isTomorrow: false,
      type: false,
      disabledType: 0,
      startTimeConvet: null,
      endTimeConvert: null,
      dateCurrent: null
    };
  },
  computed: {
    minDate() {
      return 0;
    },
    statusName() {
      return this.active ? "Ativo" : "Inativo";
    },
    isValidate() {
      return this.name !== "" && this.startTimeConvet && this.endTimeConvert;
    },
    daySelected() {
      const { day } = this.$route.query;
      return Number(day || moment().format("e"));
    }
  },
  mounted() {
    this.setupProgram();
    if (this.id) {
      this.dataCurrent = this.program.dateCurrent;
      this.startTime = this.program.startTime;
      this.endTime = this.program.endTime;
      this.startTimeConvet = this.timeUTC(this.startTime);
      this.endTimeConvert = this.timeUTC(this.endTime);
    }
  },
  beforeDestroy() {
    clearTimeout(this.messageTimeout);
    bffRealtime.cancelRequests("controlPanel");
  },
  methods: {
    async setupProgram() {
      let typeTemp = "P";
      if (this.program) this.getProgram(this.program);
      else {
        const { network, market } = this.$route.query;
        if (typeof this.type === null) {
          typeTemp = "P";
        } else {
          typeTemp = this.type;
        }
        this.type = typeTemp === "P" ? true : false;
        this.network = network;
        this.market = market;
        this.weekDay = this.daySelected;
        this.disabledType = 0;
        if (this.network === "GLOBO") {
          if (
            market.includes("RJ") ||
            market.includes("BH") ||
            market.includes("DF") ||
            market.includes("REC") ||
            market.includes("SP")
          ) {
            this.disabledType = 1;
            this.type = true;
          }
        }
      }
    },
    getNext() {
      this.getProgram(this.nextProgram());
    },
    getPrev() {
      this.getProgram(this.prevProgram());
    },
    async getProgram({
      id,
      name,
      imageUrl,
      market,
      network,
      startTime,
      endTime,
      isTomorrow,
      weekDay,
      type
    }) {
      this.message = "";
      this.messageError = "";
      this.id = id;
      this.name = name;
      this.imageUrl = imageUrl;
      this.market = market;
      this.network = network;
      this.startTime = startTime;
      this.endTime = endTime;
      this.isTomorrow = isTomorrow;
      this.type = type;
      this.weekDay = weekDay;
    },
    async addProgram() {
      clearTimeout(this.messageTimeout);
      this.isLoading = true;
      const typeTemp = "P";
      this.startTime = this.startTimeConvet;
      this.endTime = this.endTimeConvert;
      try {
        const {
          name,
          imageUrl,
          market,
          network,
          weekDay,
          startTime,
          endTime,
          isTomorrow,
          type
        } = this;

        let params = {
          name,
          imageUrl,
          market,
          network,
          weekDay,
          startTime,
          endTime,
          isTomorrow,
          type
        };
        if (this.id) {
          await bffRealtime.updateProgram(
            this.id,
            params,
            bffRealtime.tokenRequest("controlPanel", "addProgram")
          );
          params.startTime = this.timeUTCAdd(
            params.startTime,
            this.program.dateCurrent
          );
          params.endTime = this.timeUTCAdd(
            params.endTime,
            this.program.dateCurrent
          );
          this.message = "Programa alterado com sucesso!";
        } else {
          params.source = "Manual";
          params.type = typeTemp;
          const { data } = await bffRealtime.addProgram(
            params,
            bffRealtime.tokenRequest("controlPanel", "addProgram")
          );

          this.id = data.id;
          this.imageUrl = data.imageUrl;
          this.active = true;
          this.message = "Programa adicionado com sucesso!";
        }

        if (this.updateProgram) {
          params.id = this.id;
          params.imageUrl = this.imageUrl;
          params.type = typeTemp;
          this.updateProgram(params);
        }
      } catch (error) {
        console.error(error);
        this.messageError =
          "O horário preenchido se sobrepõe com o de outro programa.";
      } finally {
        this.isLoading = false;
        this.messageTimeout = setTimeout(() => {
          this.message = "";
          this.messageError = "";
        }, 2000);
      }
    }
  }
};
</script>

<style lang='scss' scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/sidebar-content.scss";
</style>
