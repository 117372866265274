import bffRealtime from '@/gateways/bff-realtime';

// initial state
const state = {
  markets: [],
};

// getters
const getters = {
  allTvNetworksId: state => (
    state.markets.reduce((acc, market) => {
      const newTvNetworks = market.tvNetworks.filter(
        tvNetwork => acc.indexOf(tvNetwork.id) < 0,
      ).map(tvNetwork => tvNetwork.id);
      return [...acc, ...newTvNetworks];
    }, [])
  ),

  markets: (state, _, rootState) => rootState
    .settings.marketIdOrder.reduce(
      (acc, id) => {
        let marketTemp = state.markets.map(object => ({ ...object }));

        marketTemp = marketTemp.filter(item => item.exhibitionName !== 'FLO');
        marketTemp = marketTemp.filter(item => item.exhibitionName !== 'POA');
        marketTemp = marketTemp.filter(item => item.exhibitionName !== 'MAN');
        marketTemp = marketTemp.filter(item => item.id !== 'FOT');
        marketTemp = marketTemp.filter(item => item.id !== 'RB');
        marketTemp = marketTemp.filter(item => item.id !== 'MAC');
        marketTemp = marketTemp.filter(item => item.id !== 'PVE');
        marketTemp = marketTemp.filter(item => item.id !== 'BVI');
        marketTemp = marketTemp.filter(item => item.id !== 'CAM');
        marketTemp = marketTemp.filter(item => item.id !== 'SOR');
        marketTemp = marketTemp.filter(item => item.id !== 'SJP');
        marketTemp = marketTemp.filter(item => item.id !== 'BAU');
        marketTemp = marketTemp.filter(item => item.id !== 'ITA');
        marketTemp = marketTemp.filter(item => item.id !== 'CAB');
        marketTemp = marketTemp.filter(item => item.id !== 'SLU');

        let market = [];
        if (rootState.settings.isSimuCastView) {
          market = state.markets.find(item => item.id === id);
        } else if (rootState.settings.isSprintView || rootState.settings.isRailView) {
         //marketTemp = marketTemp.filter(item => item.id !== 'PNT');
          market = marketTemp.find(item => item.id === id);
        } else {
          market = marketTemp.find(item => item.id === id);
        }

        return (market)
          ? [...acc, market]
          : acc;
      }, [],
    ),
  tvNetworks: (state, _, rootState) => {
    let marketSelected = rootState.settings.market.id;
    if (rootState.settings.isSprintView) {
      if (marketSelected === 'RPT' || marketSelected === 'PNT' || marketSelected === 'RPP') {
        marketSelected = rootState.settings.marketIdOrder[0];
      }
    }

    const market = state.markets.find(
      item => (item.id === (rootState.settings.isMarketView
        ? marketSelected : rootState.settings.marketIdOrder[0])),
    );

    if (!market) return [];
    const tvNetworkList = rootState.settings.tvNetworkIdOrder.reduce(
      (acc, id) => {
        const tvNetwork = market.tvNetworks.find(item => item.id === id);
        return (tvNetwork)
          ? [...acc, tvNetwork]
          : acc;
      }, [],
    );

    //Colocar a Globo sempre no início da lista de emissoras
    const globoPosition = tvNetworkList.findIndex(tv => tv.id === 'GLOBO');
    if (globoPosition > 0) {
      const globoData = tvNetworkList[globoPosition];
      tvNetworkList.splice(globoPosition, 1);
      tvNetworkList.unshift(globoData);
    }

    return tvNetworkList;
  },
  // Start Networks - mock
  tvNetworks_v2: () => ({
    GLOBO: {
      customName: 'TV GLOBO',
      customShortName: 'GLO',
      customColor: '#0025a0',
      customLogoPath: 'static/tv-network/brand-globo-color.png',
    },
    REC: {
      customName: 'TV RECORD',
      customShortName: 'REC',
      customColor: '#D65142',
      customLogoPath: 'static/tv-network/brand-record-color.png',
    },
    SBT: {
      customName: 'SBT',
      customShortName: 'SBT',
      customColor: '#5EB63E',
      customLogoPath: 'static/tv-network/brand-sbt-color.png',
    },
    BAND: {
      customName: 'BAND',
      customShortName: 'BAND',
      customColor: '#D743BF',
      customLogoPath: 'static/tv-network/brand-band-color.png',
    },
    RTV: {
      customName: 'REDE TV',
      customShortName: 'RTV',
      customColor: '#EC7C32',
      customLogoPath: 'static/tv-network/brand-redetv-color.png',
    },
    CNT: {
      customName: 'CNT',
      customShortName: 'CNT',
      customColor: '#3BC886',
      customLogoPath: 'static/tv-network/brand-cnt-color.png',
    },
    CUL: {
      customName: 'TV CULTURA',
      customShortName: 'CUL',
      customColor: '#62A24E',
      customLogoPath: 'static/tv-network/brand-tvcultura-color.png',
    },
    GAZ: {
      customName: 'TV GAZETA',
      customShortName: 'GAZ',
      customColor: '#BDA342',
      customLogoPath: 'static/tv-network/brand-gazeta-color.png',
    },
    RPT: {
      customName: 'TV BRASIL',
      customShortName: 'RPT',
      customColor: '#007fa3',
      customLogoPath: 'static/tv-network/brand-tvbrasil-color.png',
    },
    RNW: {
      customName: 'RECORD NEWS',
      customShortName: 'RNW',
      customColor: '#A88114',
      customLogoPath: 'static/tv-network/brand-recordnews-color.png',
    },
    RBR: {
      customName: 'REDE BRASIL',
      customShortName: 'RBR',
      customColor: '#627964',
      customLogoPath: 'static/tv-network/brand-redebrasil-color.png',
    },
    APA: {
      customName: 'TV APARECIDA',
      customShortName: 'APA',
      customColor: '#E7B403',
      customLogoPath: 'static/tv-network/brand-aparecida-color.png',
    },
    RIT: {
      customName: 'RIT TV',
      customShortName: 'RIT',
      customColor: '#872E85',
      customLogoPath: 'static/tv-network/brand-rit-color.png',
    },
    RVI: {
      customName: 'REDE VIDA',
      customShortName: 'RVI',
      customColor: '#F47DD9',
      customLogoPath: 'static/tv-network/brand-redevida-color.png',
    },
    GLN: {
      customName: 'GLOBO NEWS',
      customShortName: 'GLN',
      customColor: '#F4454F',
      customLogoPath: 'static/tv-network/brand-globonews-color.png',
    },
    BDN: {
      customName: 'BAND NEWS',
      customShortName: 'BDN',
      customColor: '#AF3E7E',
      customLogoPath: 'static/tv-network/brand-bandnews-color.png',
    },
    MSW: {
      customName: 'MULTISHOW',
      customShortName: 'MSW',
      customColor: '#F81E7F',
      customLogoPath: 'static/tv-network/brand-multishow-color.png',
    },
    SPC: {
      customName: 'SPACE',
      customShortName: 'SPC',
      customColor: '#FFC600',
      customLogoPath: 'static/tv-network/brand-space-color.png',
    },
    TNT: {
      customName: 'TNT',
      customShortName: 'TNT',
      customColor: '#FF4B57',
      customLogoPath: 'static/tv-network/brand-tnt-color.png',
    },
    SPO: {
      customName: 'SPORTV',
      customShortName: 'SPO',
      customColor: '#205EAB',
      customLogoPath: 'static/tv-network/brand-sportv-nova-color.png',
    },
    SP2: {
      customName: 'SPORTV2',
      customShortName: 'SP2',
      customColor: '#788eab',
      customLogoPath: 'static/tv-network/brand-sportv2-nova-color.png',
    },
    SP3: {
      customName: 'SPORTV3',
      customShortName: 'SP3',
      customColor: '#5198f0',
      customLogoPath: 'static/tv-network/brand-sportv3-nova-color.png',
    },
    SP4: {
      customName: 'SPORTV4',
      customShortName: 'SP4',
      customColor: '#09043e',
      customLogoPath: 'static/tv-network/brand-sportv4-nova-color.png',
    },
    EIT: {
      customName: 'ESPORTE INTERATIVO',
      customShortName: 'EIT',
      customColor: '#E20612',
      customLogoPath: 'static/tv-network/brand-esporteinterativo-color.png',
    },
    BDS: {
      customName: 'BAND SPORT',
      customShortName: 'BDS',
      customColor: '#D552A0',
      customLogoPath: 'static/tv-network/brand-bandsports-color.png',
    },
    FXS: {
      customName: 'FOX SPORTS',
      customShortName: 'FXS',
      customColor: '#244D83',
      customLogoPath: 'static/tv-network/brand-foxsports-color.png',
    },
    FS2: {
      customName: 'FOX SPORTS 2',
      customShortName: 'FS2',
      customColor: '#244D83',
      customLogoPath: 'static/tv-network/brand-foxsports2-color.png',
    },
    ESP: {
      customName: 'ESPN',
      customShortName: 'ESP',
      customColor: '#EF3F32',
      customLogoPath: 'static/tv-network/brand-espn-color.png',
    },
    ES2: {
      customName: 'ESPN2',
      customShortName: 'ES2',
      customColor: '#EF3F32',
      customLogoPath: 'static/tv-network/brand-espn2-color.png',
    },
    ES3: {
      customName: 'ESPN3',
      customShortName: 'ES3',
      customColor: '#EF3F32',
      customLogoPath: 'static/tv-network/brand-espn3-color.png',
    },
    ES4: {
      customName: 'ESPN4',
      customShortName: 'ES4',
      customColor: '#EF3F32',
      customLogoPath: 'static/tv-network/brand-espn4-color.png',
    },
    ES5: {
      customName: 'ESPN5',
      customShortName: 'ES5',
      customColor: '#EF3F32',
      customLogoPath: 'static/tv-network/brand-espn5-color.png',
    },
    ESB: {
      customName: 'ESPN BRASIL',
      customShortName: 'ESB',
      customColor: '#EF3F32',
      customLogoPath: 'static/tv-network/brand-espnbrasil-color.png',
    },
    'ES+': {
      customName: 'ESPN+',
      customShortName: 'ES+',
      customColor: '#EF3F32',
      customLogoPath: 'static/tv-network/brand-espnplus-color.png',
    },
    TOTP: {
      customName: 'TOTAL PAYTV',
      customShortName: 'TOTP',
      customColor: '#32CCFE',
      customLogoPath: 'static/tv-network/brand-totalpaytv-color.png',
    },
    TOTA: {
      customName: 'TOTAL ABERTAS',
      customShortName: 'TOTA',
      customColor: '#333333',
      customLogoPath: 'static/tv-network/brand-totalabertas-color.png',
    },
    TOTS: {
      customName: 'TOTAL SPORTS',
      customShortName: 'TOTS',
      customColor: '#FF6600',
      customLogoPath: 'static/tv-network/brand-totalsports-color.png',
    },
    TVE: {
      customName: 'TV EDUCATIVA',
      customShortName: 'TVE',
      customColor: '#FF6600',
      customLogoPath: 'static/tv-network/brand-tve-color.png',
    },
    CRT: {
      customName: 'CRT',
      customShortName: 'CRT',
      customColor: '#831D1C',
    },
    OCP: {
      customName: 'PAGOS',
      customShortName: 'PAGOS',
      customColor: '#0E62AB',
      customLogoPath: 'static/tv-network/canais-pagos-color.png',
    },
    TLE: {
      customColor: '#A9A9A9',
    },
    TL: {
      customColor: '#000000',
    },
    CNN: {
      customName: 'CNN',
      customShortName: 'CNN',
      customColor: '#CC0000',
      customLogoPath: 'static/tv-network/brand-cnn-color.png',
    },
    IND: {
      customName: 'TV INDEPENDENTE',
      customShortName: 'IND',
      customColor: '#CCCCCC',
      customLogoPath: 'static/tv-network/brand-ind-color.png',
    },
    JPN: {
      customName: 'Jovem Pan News',
      customShortName: 'JPN',
      customColor: '#6a7885',
      customLogoPath: 'static/tv-network/brand-jovem-pan-news-color.png',
    },
    T98: {
      customName: 'TV 98',
      customShortName: 'T98',
      customColor: '#FF4B57',
      customLogoPath: 'static/tv-network/brand-tv98-color.png',
    },
    NIC: {
      customName: 'NI CONTEUDO',
      customShortName: 'NIC',
      customColor: '#833B0C',
      customLogoPath: 'static/tv-network/ni-conteudo.png',
    },
  }),
  // END Networks - mock

  marketSimulcast: () => ({
    RJ: {
      customName: 'RIO DE JANEIRO',
      customShortName: 'RJ',
      customColor: '#0025a0',
      customLogoPath: 'static/tv-network/brand-globo-color.png',
    },
    SP: {
      customName: 'SÃO PAULO',
      customShortName: 'SP',
      customColor: '#D65142',
      customLogoPath: 'static/tv-network/brand-record-color.png',
    },
    BH: {
      customName: 'BELO HORIZONTE',
      customShortName: 'BH',
      customColor: '#5EB63E',
      customLogoPath: 'static/tv-network/brand-sbt-color.png',
    },
    FOR: {
      customName: 'FORTALEZA',
      customShortName: 'FOR',
      customColor: '#5EB63E',
      customLogoPath: 'static/tv-network/brand-sbt-color.png',
    },
    MAN: {
      customName: 'MANAUS',
      customShortName: 'MAN',
      customColor: '#D743BF',
      customLogoPath: 'static/tv-network/brand-band-color.png',
    },
    GOI: {
      customName: 'GOIÁS',
      customShortName: 'GOI',
      customColor: '#EC7C32',
      customLogoPath: 'static/tv-network/brand-redetv-color.png',
    },
    POA: {
      customName: 'PORTO ALEGRE',
      customShortName: 'POA',
      customColor: '#3BC886',
      customLogoPath: 'static/tv-network/brand-cnt-color.png',
    },
    CUR: {
      customName: 'CURITIBA',
      customShortName: 'CUR',
      customColor: '#62A24E',
      customLogoPath: 'static/tv-network/brand-tvcultura-color.png',
    },
    BEL: {
      customName: 'BELÉM',
      customShortName: 'BEL',
      customColor: '#BDA342',
      customLogoPath: 'static/tv-network/brand-gazeta-color.png',
    },
    REC: {
      customName: 'RECIFE',
      customShortName: 'REC',
      customColor: '#007fa3',
      customLogoPath: 'static/tv-network/brand-tvbrasil-color.png',
    },
    SAL: {
      customName: 'SALVADOR',
      customShortName: 'SAL',
      customColor: '#A88114',
      customLogoPath: 'static/tv-network/brand-recordnews-color.png',
    },
    DF: {
      customName: 'DISTRITO FEDERAL',
      customShortName: 'DF',
      customColor: '#627964',
      customLogoPath: 'static/tv-network/brand-redebrasil-color.png',
    },
    FLO: {
      customName: 'FLORIANÓPOLIS',
      customShortName: 'FLO',
      customColor: '#E7B403',
      customLogoPath: 'static/tv-network/brand-aparecida-color.png',
    },
    RB: {
      customName: 'RIO BRANCO',
      customShortName: 'RB',
      customColor: '#872E85',
      customLogoPath: 'static/tv-network/brand-rit-color.png',
    },
    CAM: {
      customName: 'CAMPINAS',
      customShortName: 'CAM',
      customColor: '#F47DD9',
      customLogoPath: 'static/tv-network/brand-redevida-color.png',
    },
    SJP: {
      customName: 'SÃO JOSÉ DO RIO PRETO',
      customShortName: 'SJP',
      customColor: '#F4454F',
      customLogoPath: 'static/tv-network/brand-globonews-color.png',
    },
    BAU: {
      customName: 'BAURU',
      customShortName: 'BAU',
      customColor: '#AF3E7E',
      customLogoPath: 'static/tv-network/brand-bandnews-color.png',
    },
    VIT: {
      customName: 'VITÓRIA',
      customShortName: 'VIT',
      customColor: '#F81E7F',
      customLogoPath: 'static/tv-network/brand-multishow-color.png',
    },
    ITA: {
      customName: 'ITAPETININGA',
      customShortName: 'ITA',
      customColor: '#FFC600',
      customLogoPath: 'static/tv-network/brand-space-color.png',
    },
    MAC: {
      customName: 'MACAPÁ',
      customShortName: 'MAC',
      customColor: '#FF4B57',
      customLogoPath: 'static/tv-network/brand-tnt-color.png',
    },
    PVE: {
      customName: 'PORTO VELHO',
      customShortName: 'PVE',
      customColor: '#205EAB',
      customLogoPath: 'static/tv-network/brand-sportv-color.png',
    },
    BVI: {
      customName: 'BOA VISTA',
      customShortName: 'BVI',
      customColor: '#205EAB',
      customLogoPath: 'static/tv-network/brand-sportv2-color.png',
    },
    SOR: {
      customName: 'SOROCABA',
      customShortName: 'SOR',
      customColor: '#205EAB',
      customLogoPath: 'static/tv-network/brand-sportv3-color.png',
    },
    CAB: {
      customName: 'CABUGI',
      customShortName: 'CAB',
      customColor: '#E20612',
      customLogoPath: 'static/tv-network/brand-esporteinterativo-color.png',
    },
    SLU: {
      customName: 'SÃO LUÍS',
      customShortName: 'SLU',
      customColor: '#D552A0',
      customLogoPath: 'static/tv-network/brand-bandsports-color.png',
    },
  }),
  // END marketSimulcast - mock
};


// actions
const actions = {
  async getMarkets({ commit }) {
    const { data } = await bffRealtime.getMarkets();
    commit('SET_MARKETS', data.source);
  },
};

// mutations
const mutations = {
  SET_MARKETS(state, markets) {
    state.markets = markets;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
