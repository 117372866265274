<template>
  <div ref="content" class="table_container">
    <transition name="fade">
      <div v-if="isLoading" class="loading">
        <div class="loader">
          <svg
            class="spinner"
            width="65px"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              class="path"
              fill="none"
              stroke-width="6"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
    </transition>
    <template v-if="programs">
      <div v-if="networks" class="tags program">
        <button
          v-for="network in Object.keys(networks)"
          :key="network"
          type="button"
          class="btn ripple tag"
          :class="buttonTvNetworkCss(network)"
          @click="selectNetwork(network)"
        >
          <img v-if="getNetworkLogo(network)" :src="getNetworkLogo(network)">
          <div v-else :style="{ color: getNetworkColor(network) }">
            {{ network }}
          </div>
        </button>
      </div>
      <div v-if="markets" class="tags program">
        <button
          v-for="market in markets"
          :key="market"
          type="button"
          class="btn ripple tag"
          :class="buttonMarketCss(market)"
          @click="selectMarket(market)"
        >
          {{ market }}
        </button>
      </div>
      <ErrorState
        v-if="(programsList.length === 0 && !settings.isLoadingSpinner && !isLoading ) "
        :insides="['globo_item']"
        title="Xiiiiiiiiiiiiii"
        :subtitle="(!settings.isImportActivate) ? 'Não encontramos nenhum programa' : 'Não existem dados no EPG para essa praça e canal'"
        :style-container="{
          backgroundColor: 'transparent',
        }"
        :fullscreen="false"
      />

      <!--<template v-else-if="!settings.isLoadingSpinner">-->
      <template>
        <div class="middle">
          <table class="table">
            <thead v-if="settings.isLoadingSpinner && !settings.isImportActivate">
              <tr>
                <th class="name">
                  Nome do Programa ou Quadro
                </th>
                <th class="date">
                  TIpo
                </th>
                <th class="date">
                  Início
                </th>
                <th class="date">
                  FIm
                </th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="program in programsList" :key="program.id">
                <div class="actions" :class="{active: isCurrentProgram(program.id)}">
                  <div class="icones">
                    <i
                      v-if="!isG5(networkCurrent,marketCurrent) || program.type==='Q'"
                      class="icone material-icons tool"
                      data-tip="Deletar"
                      @click="removeConfirmation(program)"
                    >delete</i>
                    <i
                      v-if="program.type==='P'"
                      class="icone material-icons tool"
                      data-tip="Criar Quadro"
                      @click="addFrame(program)"
                    >add</i>
                    <i
                      class="icone material-icons tool"
                      data-tip="Editar"
                      @click="open(program)"
                    >edit</i>
                  </div>
                </div>

                <td class="name">
                  <template v-if="program.type==='Q'">
                    &nbsp;
                    <i class="icone material-icons">keyboard_arrow_right</i>
                  </template>
                  {{ program.name || '--' }}
                </td>
                <td class="date">
                  <template v-if="program.type==='P'">
                    Programa
                  </template>
                  <template v-if="program.type==='Q'">
                    Quadro
                  </template>
                </td>
                <td class="date">
                  {{ timeUTC(program.startTime, dateCurrent) }}
                </td>
                <td class="date">
                  {{ timeUTC(program.endTime, dateCurrent) }}
                </td>
                <td>
                  <i class="icone material-icons">more_horiz</i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <paginate
          ref="pagination"
          :page-count="pages"
          :click-handler="pageHandler"
          first-button-text="Primeiro"
          last-button-text="Último"
          :first-last-button="true"
          prev-text="Anterior"
          next-text="Próximo"
          container-class="paginate"
        />
      </template>
    </template>
    <!-- fim do conteudo com tabela + pagination -->
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';
import Paginate from 'vuejs-paginate';
import ErrorState from '@/components/system/ErrorState.vue';
import dateUTC from '@/utils/dateUTC';

export default {
  components: {
    Paginate,
    ErrorState,
  },
  mixins: [dateUTC],
  data() {
    return {
      programs: null,
      pageNumber: 1,
      perPage: 15,
      activeProgram: 0,
      isLoading: true,
      networks: null,
      disabledAddProgram: false,
      networkCurrent: null,
      marketCurrent: null,
      dateCurrent: null,
    };
  },
  computed: {
    ...mapState(['settings', 'system']),
    ...mapGetters(['currentUser', 'tvNetworks_v2']),
    detectProcessImport() {
      return this.settings.isImportActivate;
    },
    pages() {
      return Math.ceil(this.programsFiltered.length / this.perPage);
    },
    daySelected() {
      const { day } = this.$route.query;
      return Number(day || moment().format('e'));
    },
    programsFiltered() {
      const {
        search, market, network, type,
      } = this.$route.query;

      return this.programs.filter(program => (
        this.isMatch(program.name, search)
          && program.weekDay === this.daySelected
          && program.market === market
          && program.network === network
          // -- && program.type === type
      ));
    },
    programsList() {
      const begin = (this.pageNumber - 1) * this.perPage;
      const end = begin + this.perPage;
      return this.programsFiltered.slice(begin, end);
    },
    markets() {
      const { network } = this.$route.query;
      return network ? this.networks[network] : [];
    },
  },
  watch: {
    detectProcessImport() {
      if (!this.settings.isImportActivate) {
        this.setupPrograms();
      }
    },
    '$route.query': {
      handler() {
        // Back to page 0
        this.goToFirstPage();
      },
    },
  },
  mounted() {
    this.setupPrograms();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    bffRealtime.cancelRequests('controlPanel');
  },
  methods: {
    isG5(network, market) {
      const marketG5 = ['SP', 'RJ', 'BH', 'REC', 'DF'];
      if (marketG5.includes(market) && network === 'GLOBO') {
        return true;
      }
      return false;
    },
    async getDateCurrent() {
      this.isLoading = true;
      try {
        const { data } = await bffRealtime.getCurrentUser(
          bffRealtime.tokenRequest('controlPanel', 'getCurrentUser'),
        );
        this.dateCurrent = this.formatDate(data.updatedAt);
      } catch (error) {
        if (error && error.response && error.response.status === 405) {
          this.$store.dispatch('showInnerError', {
            insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
            subtitle:
              'Você está fora da janela de restrições configurada para seu usuário. Por favor, retorne no horário definido para seu usuário.',
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    getDate(unixtime) {
      return moment.unix(unixtime).format('YYYY/MM/DD');
    },
    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId]
        && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath
        : '';
    },
    getNetworkColor(networkId) {
      return this.tvNetworks_v2[networkId]
        && this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor
        : '';
    },
    async setupPrograms() {
      this.$store.dispatch('selectIsImportActivate', false);
      window.addEventListener('resize', this.handleResize);
      await this.getProgramConfig();
      this.setPerPage();
      this.getPrograms();
      this.getDateCurrent();
    },
    addProgram() {
      this.$modal.show('sidebar', {
        componentName: 'CreateProgram',
        title: 'Novo Programa',
        icon: 'tv',
        onClose: () => {
          this.$store.dispatch('selectIsLoading', true);
        },
      });
    },
    addFrame(program) {
      program.id = null;
      this.$modal.show('sidebar', {
        componentName: 'CreateFrame',
        program,
        title: 'Novo Quadro',
        icon: 'dvr',
        onClose: () => {
          this.$store.dispatch('selectIsLoading', true);
        },
      });
    },
    isMatch(text, search) {
      if (!search || search === '') return true;
      const string = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(string, 'ig');
      return regex.test(text.trim());
    },
    setPerPage() {
      const columnHeight = 48; //  This number should be the same height has been defined in css
      const paginationHeight = 64 + 52 + 52; // This number should be the same height has been defined in css
      const contentHeight = this.$refs.content.clientHeight;
      this.perPage = Math.floor(
        (contentHeight - paginationHeight - 32) / columnHeight,
      );
      this.goToFirstPage();
    },
    handleResize() {
      this.$modal.hide('sidebar');
      this.setPerPage();
    },
    goToFirstPage() {
      if (!this.$refs.pagination) return;
      this.$refs.pagination.selectFirstPage();
    },
    pageHandler(page) {
      this.pageNumber = page;
    },
    async getProgramConfig() {
      this.isLoading = true;
      try {
        const { data } = await bffRealtime.getProgramConfig(
          bffRealtime.tokenRequest('controlPanel', 'getProgramConfig'),
        );
        this.networks = data.networks;
        const { market, network } = this.$route.query;
        this.disabledAddProgram = this.isG5(network, market);
        this.networkCurrent = network;
        this.marketCurrent = market;
        if (!market || !network) {
          const firstNetwork = Object.keys(this.networks).shift();
          const query = {
            ...this.$route.query,
            market: this.networks[firstNetwork][0],
            network: firstNetwork,
          };
          this.$router.push({ query });
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getPrograms() {
      this.isLoading = true;
      try {
        const { data } = await bffRealtime.getPrograms(
          bffRealtime.tokenRequest('controlPanel', 'getPrograms'),
        );
        const dataList = [];
        data.forEach((e) => {
          // PROGRAM HERE
          dataList.push({
            id: e.id,
            idProgram: e.id,
            name: e.name,
            nameFrame: null,
            nameProgram: e.name,
            market: e.market,
            network: e.network,
            weekDay: e.weekDay,
            startTime: e.startTime,
            endTime: e.endTime,
            isTomorrow: e.isTomorrow,
            source: e.source,
            type: 'P',
            imageUrl: e.imageUrl,
            schedule: e.schedule,
          });
          if (e.schedule) {
            if (e.schedule.length > 0) {
              e.schedule.forEach((s) => {
              // FRAME HERE
                dataList.push({
                  id: s.id,
                  idProgram: e.id,
                  name: s.name,
                  nameFrame: s.name,
                  nameProgram: e.name,
                  market: e.market,
                  network: e.network,
                  weekDay: e.weekDay,
                  startTime: s.startTime,
                  endTime: s.endTime,
                  source: e.source,
                  type: 'Q',
                });
              });
            }
          }
        });
        this.programs = dataList;
      } catch (error) {
        if (error && error.response && error.response.status === 405) {
          this.$store.dispatch('showInnerError', {
            insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
            subtitle:
              'Você está fora da janela de restrições configurada para seu usuário. Por favor, retorne no horário definido para seu usuário.',
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    clearActiveProgram() {
      this.activeProgram = 0;
    },
    isCurrentProgram(id) {
      return this.activeProgram === id;
    },
    updateProgram(data) {
      const index = this.programs.findIndex(({ id }) => id === data.id);
      if (index > -1) {
        this.programs.splice(index, 0); // This is needed to update the variable by Vue
        this.programs[index] = data;
      } else this.programs.push(data);
    },
    nextProgram() {
      let nextId = this.programsList.findIndex(({ id }) => id === this.activeProgram) + 1;
      if (nextId === this.programsList.length) {
        this.$refs.pagination.nextPage();
        nextId = 0;
      }
      const program = this.programsList[nextId];
      this.activeProgram = program.id;
      return program;
    },
    prevProgram() {
      let prevId = this.programsList.findIndex(({ id }) => id === this.activeProgram) - 1;
      if (prevId === -1) {
        this.$refs.pagination.prevPage();
        prevId = this.programsList.length - 1;
      }
      const program = this.programsList[prevId];
      this.activeProgram = program.id;
      return program;
    },
    removeProgramConfirmation(id) {
      this.$modal.show('dialog', {
        title: 'Confirmação',
        text: 'Deseja realmente excluir o programa selecionado?',
        buttons: [
          {
            title: 'Manter',
          },
          {
            title: 'Excluir',
            handler: () => {
              this.removeProgram(id);
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    removeFrameConfirmation(id, idProgram) {
      this.$modal.show('dialog', {
        title: 'Confirmação',
        text: 'Deseja realmente excluir o quadro selecionado?',
        buttons: [
          {
            title: 'Manter',
          },
          {
            title: 'Excluir',
            handler: () => {
              this.removeFrame(id, idProgram);
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    removeConfirmation(program) {
      program.type === 'P'
        ? this.removeProgramConfirmation(program.id)
        : this.removeFrameConfirmation(program.id, program.idProgram);
    },
    async removeProgram(id) {
      this.showLoading();
      const { market, network } = this.$route.query;

      try {
        await bffRealtime.removeProgram(
          id, market, network, this.daySelected,
          bffRealtime.tokenRequest('controlPanel', 'removeProgram'),
        );

        this.programs = this.programs.filter(pro => pro.id !== id);
        this.programs = this.programs.filter(pro => pro.idProgram !== id);
      } catch (error) {
        throw error;
      } finally {
        this.hideLoading();
      }
    },

    async removeFrame(id, idProgram) {
      this.showLoading();
      try {
        const { market, network } = this.$route.query;

        await bffRealtime.removeFrame(
          idProgram,
          id,
          market,
          network,
          this.daySelected,
          bffRealtime.tokenRequest('controlPanel', 'removeFrame'),
        );

        this.programs = this.programs.filter(pro => pro.id !== id);
      } catch (error) {
        throw error;
      } finally {
        this.hideLoading();
      }
    },
    openPrograms(program) {
      program.dateCurrent = this.dateCurrent;
      this.activeProgram = program.id;
      this.$modal.show('sidebar', {
        componentName: 'CreateProgram',
        title: 'Programas',
        icon: 'tv',
        onClose: this.clearActiveProgram,
        updateProgram: this.updateProgram,
        nextProgram: this.nextProgram,
        prevProgram: this.prevProgram,
        program,
      });
    },
    openFrames(program) {
      program.dateCurrent = this.dateCurrent;
      this.activeProgram = program.id;
      this.$modal.show('sidebar', {
        componentName: 'CreateFrame',
        title: 'Quadro',
        icon: 'dvr',
        onClose: this.clearActiveProgram,
        program,
      });
    },
    open(program) {
      program.type === 'P'
        ? this.openPrograms(program)
        : this.openFrames(program);
    },
    showLoading() {
      this.isLoading = true;
    },
    hideLoading() {
      this.isLoading = false;
    },
    buttonMarketCss(id) {
      const { market } = this.$route.query;
      return {
        enabled: id === market,
      };
    },
    buttonTvNetworkCss(id) {
      const { network } = this.$route.query;
      return {
        enabled: id === network,
      };
    },
    selectMarket(market) {
      this.$store.dispatch('selectIsImportActivate', false);

      const query = { ...this.$route.query, market };
      this.$router.push({ query });
      this.networkCurrent = query.network;
      this.marketCurrent = query.market;
    },
    selectNetwork(network) {
      this.$store.dispatch('selectIsImportActivate', false);

      const query = {
        ...this.$route.query,
        network,
        market: this.networks[network][0],
      };
      this.$router.push({ query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/tags.scss";

.table_container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  /*== start of code for tooltips ==*/
  .tool {
    position: relative;
    z-index: 9999;

    &::before,
    &::after {
      left: 50%;
      opacity: 0;
      position: absolute;
      z-index: -100;
    }

    &:hover::before,
    &:focus::before,
    &:hover::after,
    &:focus::after {
      animation-delay: 0.4s !important;
      animation: fadeInDown 0.4s ease-in-out forwards;
      z-index: 100;
    }

    // Balão
    &::after {
      display: block;
      content: attr(data-tip);
      background: rgba($color-gray-lighter, 0.88);
      bottom: 120%;
      padding: 8px;
      box-sizing: border-box;
      margin-left: -54px;
      width: 108px;
      font-family: $secondary-typo;
      font-size: 0.48em;
      font-weight: 500;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 0.02em;
      color: $color-secondary;
      border-radius: 4px;
      box-shadow: 0 2px 8px rgba($color-gray-darker, 0.08);
      transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
      opacity: 0;
      pointer-events: none;
      animation: fadeOutUp 0.4s ease-in-out forwards;
    }

    &:hover::after,
    &:focus::after {
      transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .loading {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-gray-lighter, 0.8);

    &.in {
      animation: fadeIn 0.4s ease-in-out forwards;
    }

    &.out {
      animation: fadeOut 0.4s ease-in-out forwards;
    }

    .loader {
      width: 24px;
      height: 24px;

      .spinner {
        width: 100%;
        height: 100%;
        animation: rotator $duration linear infinite;
      }

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: dash $duration ease-in-out infinite,
          colors ($duration * 4) ease-in-out infinite;
      }
    }
  }

  .middle {
    flex-grow: 1;
  }
  .table {
    overflow: hidden;
    border-radius: 4px;
    border-spacing: 0;
    width: 100%;
    box-shadow: 0 2px 16px 0 rgba($color-gray-darker, 0.04);

    tr {
      display: flex;
      height: 45px;
      position: relative;

      .actions {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        // Gradient
        background: rgba(255, 255, 255, 0.24);
        background: -moz-linear-gradient(
          left,
          rgba(255, 255, 255, 0.24) 0%,
          rgba(255, 255, 255, 0.81) 48%,
          rgba(255, 255, 255, 0.92) 57%
        );
        background: -webkit-gradient(
          left top,
          right top,
          color-stop(0%, rgba(255, 255, 255, 0.24)),
          color-stop(48%, rgba(255, 255, 255, 0.81)),
          color-stop(57%, rgba(255, 255, 255, 0.92))
        );
        background: -webkit-linear-gradient(
          left,
          rgba(255, 255, 255, 0.24) 0%,
          rgba(255, 255, 255, 0.81) 48%,
          rgba(255, 255, 255, 0.92) 57%
        );
        background: -o-linear-gradient(
          left,
          rgba(255, 255, 255, 0.24) 0%,
          rgba(255, 255, 255, 0.81) 48%,
          rgba(255, 255, 255, 0.92) 57%
        );
        background: -ms-linear-gradient(
          left,
          rgba(255, 255, 255, 0.24) 0%,
          rgba(255, 255, 255, 0.81) 48%,
          rgba(255, 255, 255, 0.92) 57%
        );
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0.24) 0%,
          rgba(255, 255, 255, 0.81) 48%,
          rgba(255, 255, 255, 0.92) 57%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );

        border-bottom: 2px solid $color-secondary;
        display: block;
        justify-content: flex-end;
        align-items: center;
        padding: 0 48px 0 24px;

        &:hover {
          opacity: 1;
          cursor: pointer;
        }

        span {
          flex: 1;
        }

        .icones {
          position: absolute;
          top: 12px;
          right: 48px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin: 0;
          animation: fadeInRight 0.4s ease-in-out forwards;

          i {
            color: $color-gray-main;
            font-size: 20px;
            margin-right: 16px;
            cursor: pointer;
            transition: color 0.4s ease-in-out;

            &:hover {
              color: $color-secondary;
            }
          }
        }

        &.active {
          display: flex;
        }
      }

      &:hover {
        .actions {
          display: flex;
        }
      }

      th,
      td {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-gray-dark;
        padding: 0 24px;
        text-align: center;

        &.name {
          flex-grow: 1;
          width: 0;
        }
        &.date {
          width: 15%;
        }
        &:last-child {
          width: 100px;
          justify-content: flex-end;
        }
      }
    }

    thead {
      box-shadow: 0 2px 24px rgba($color-gray-darker, 0.08);

      tr {
        background-color: $color-gray-lighter;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    tbody {
      tr {
        background-color: $color-gray-lighter;
        font-size: 14px;
        position: relative;

        td {
          &.status {
            color: $color-inactive;
            font-weight: 500;
            text-transform: uppercase;

            &.active {
              color: $color-active;
            }
          }
        }

        &:nth-child(odd) {
          background: $color-table-odd;
        }

        &:nth-child(even) {
          background: $color-table-even;
        }
      }
    }
  }

  & /deep/ .paginate {
    list-style: none;
    justify-content: center;
    display: flex;
    margin: 0 0 16px 0;

    li {
      display: inline-block;
      padding: 8px;

      a {
        display: block;
        line-height: 46px;
        min-width: 46px;
        border-radius: 4px;
        padding: 0 16px;
        text-align: center;
        color: $color-gray-main;
        border: solid 1px $color-gray-lighter;
        font-size: 14px;
        transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out,
          color 0.56s ease-in-out;

        &:hover {
          background-color: $color-gray-lighter;
        }
      }

      &.active {
        a {
          font-weight: 500;
          color: $color-gray-lighter;
          border: solid 1px $color-secondary-darker;
          background-color: $color-secondary-darker;

          &:hover {
            background-color: $color-secondary;
          }
        }
      }

      &.disabled {
        a {
          opacity: 0.4;
          background-color: $color-gray-lighter;
          border: solid 1px $color-gray-lighter;
          pointer-events: none;
        }
      }
    }
  }
}
</style>
